<template>
  <v-dialog v-model="dialog" width="300">
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon>
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </template>
        <span>Create a new option list</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>New Option List</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field v-model="name" :error="errorMessages.length > 0" :error-messages="errorMessages" :rules="nameRules" label="Name of option list" hint="Must be unique"></v-text-field>
          <v-select v-model="type" :items="types" :rules="required" label="Type of option list"></v-select>
          <v-select v-model="editRole" :items="roleOptions" :rules="required" label="Role for who can edit this list"></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">Cancel</v-btn>
        <v-btn color="success" text @click="saveNewList">Create List</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
export default {
  setup (props, { root, emit }) {
    const dialog = ref(false)
    const form = ref(null)
    const name = ref('')
    const nameRules = ref([v => !!v || 'Required', v => v.length > 5 || 'Must be 6+ characters'])
    const type = ref('')
    const types = ref([{ text: 'Static List', value: 'static' }, { text: 'Dynamic List (pulls from API service)', value: 'dynamic' }])
    const editRole = ref('')
    const roleOptions = computed(() => root.$store.state.user.roles)
    const required = ref([v => !!v || 'Required'])

    const errorMessages = ref([])

    watch(name, (name) => {
      errorMessages.value = []
      if (name === '' || name == null) {
        errorMessages.value.push('Name is required')
        return
      }
      const query = { name, $limit: 0 }
      root.$feathers.service('forms/select-options').find({ query }).then(({ total }) => {
        if (total > 0) errorMessages.value.push('Option List with this name already exists')
      })
    })

    function saveNewList () {
      if (form.value.validate() && errorMessages.value.length === 0) {
        let obj = {
          name: name.value,
          type: type.value,
          details: '',
          editRole: '',
          visibleRoles: []
        }
        if (obj.type === 'dynamic') {
          obj.service = ''
          obj.query = ''
          obj.textField = 'text'
          obj.valueField = 'value'
        } else {
          obj.options = []
        }
        // Check to make sure there is not already a list with the same name (we cannot have 2 lists with the same name)
        root.$feathers.service('forms/select-options').create(obj).then((data) => {
          emit('created', data)
          name.value = ''
          type.value = 'static'
          editRole.value = ''
        })
        dialog.value = false
      }
    }

    return {
      dialog,
      form,
      name,
      nameRules,
      type,
      types,
      editRole,
      roleOptions,
      required,
      errorMessages,
      saveNewList
    }
  }
}
</script>
